/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import { keyframes } from '@emotion/core'
import { Map, GoogleApiWrapper } from 'google-maps-react'

import mapStyles from './mapStyles'

const pulse = keyframes`
  0% {
   width:100%;
   height:100%;
   margin:0;
   opacity:0
  }
  10% {
   opacity:1
  }
  to {
   width:11000%;
   height:11000%;
   top:-5500%;
   left:-5500%
 }
`

const MapContainer = (props) => {
  return (
    <Map
      google={props.google}
      zoom={16}
      style={{
        width: '100%',
        height: '100%',
      }}
      styles={mapStyles}
      disableDefaultUI={true}
      initialCenter={{ lat: 52.386, lng: 4.874153 }}
    >
      <div
        sx={{
          position: 'absolute',
          width: '3em',
          height: '3em',
          right: 0,
          margin: 'auto',
          bottom: 0,
          border: '1px solid #000',
          left: 0,
          top: 0,
          borderRadius: '50%',
          userSelect: 'none',
          pointerEvents: 'none',
          '&::after': {
            position: 'absolute',
            border: '1px solid #000',
            left: 0,
            top: 0,
            borderRadius: '50%',
            content: `''`,
            display: 'block',
            width: '100%',
            height: '100%',
            margin: 0,
            userSelect: 'none',
            pointerEvents: 'none',
            animation: `${pulse} 3s linear infinite`,
          },
        }}
      />
    </Map>
  )
}

const LoadingContainer = () => (
  <Flex
    sx={{
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      p: 2,
      height: '100%',
    }}
  >
    Loading…
  </Flex>
)

export default GoogleApiWrapper({
  apiKey: process.env.GOOGLE_API,
  LoadingContainer,
})(MapContainer)
