/** @jsx jsx */
import { jsx, Box, Grid, Text } from 'theme-ui'

import Layout from '@components/Layout'
import GoogleMap from '@components/Map'
import SEO from '@components/SEO'
import { ExternalLink } from '@components/Links'

const Contact = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <Grid
        sx={{
          minHeight: '100%',
          gridTemplateRows: ['max-content 1fr', 'max-content 1fr', '1fr'],
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
        }}
      >
        <Box py={4} px={3}>
          <table
            sx={{
              borderCollapse: 'collapse',
              borderSpacing: 0,
              mb: 3,
              td: {
                pr: 3,
              },
            }}
          >
            <tbody sx={{ verticalAlign: 'inherit' }}>
              <tr>
                <td>Address</td>
                <td>
                  <ExternalLink
                    sx={{ p: 0, fontStyle: 'italic' }}
                    href="https://goo.gl/maps/xco2g8xoGCZvpSci6"
                  >
                    Pazzanistraat 3
                  </ExternalLink>{' '}
                  <br />
                  1014 DB Amsterdam
                </td>
              </tr>
              <tr>
                <td>E-mail</td>
                <td>
                  <p>
                    <ExternalLink
                      sx={{ p: 0, fontStyle: 'italic' }}
                      href="mailto:radio@radioradio.radio"
                    >
                      radio@radioradio.radio
                    </ExternalLink>
                  </p>
                </td>
              </tr>
              <tr>
                <td>Opening hours</td>
                <td>
                  <table
                    sx={{
                      borderCollapse: 'collapse',
                      borderSpacing: 0,
                      mb: 3,
                      td: {
                        pr: 3,
                      },
                    }}
                  >
                    <tbody sx={{ verticalAlign: 'inherit' }}>
                      <tr>
                        <td>Friday</td>
                        <td>18.00 - 00.00</td>
                      </tr>
                      <tr>
                        <td>Saturday</td>
                        <td>16.00 - 00.00</td>
                      </tr>
                      <tr>
                        <td>Sunday</td>
                        <td>16.00 - 00.00</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <Text>
            Radio Radio is also for rent from Sunday until Thursday. Friday and
            Saturday are always programmed months in advance and open to the
            public.
          </Text>
        </Box>
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            borderLeft: ['none', 'none', '1px solid black'],
            borderTop: ['1px solid black', '1px solid black', 'none'],
            pointerEvents: 'none',
            minHeight: '220px',
          }}
        >
          <GoogleMap google="https://api.google.com/some/script.js"></GoogleMap>
        </Box>
      </Grid>
    </Layout>
  )
}

export default Contact
